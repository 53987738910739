import styled from 'styled-components'
import { device, width } from '../../styles/device'

const ProductWrapV2Styled = styled.div`
    width: 100%;
    padding-top: 70px;
    padding-bottom: 30px;

        @media ${device.tablet} {
            padding-top: 80px;
            padding-bottom: 50px;
        }
    }

    .container {
        max-width: ${width.xl};
        display: flex;
        flex-direction: column;
        margin: 0 auto;


        @media ${device.mobile} {
            flex-direction: row;
        }
    }
`;

export default ProductWrapV2Styled
